import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Amplify } from "aws-amplify";
import { Storage, AWSS3Provider, S3ProviderPutConfig } from "@aws-amplify/storage";
import {v4 as uuid} from "uuid";
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
  Pagination, 
  TextFilter,
  Table
} from "@cloudscape-design/components";

import awsconfig from "../constants/amplifyConfiguration"; 



Amplify.configure(awsconfig);


export default function CustomerMessagesTable() {

  const ref = useRef();
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  
  
  const [ fileToUpload, setFileToUpload ] = useState();
  const [ uploadStatus, setUploadStatus ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ isFlashbarDisplayed, setIsFlashbarDisplayed ] = useState(false);
  const [ flashbarItems, setFlashbarItems ] = useState([
    {
      type: "success",
      content: "The file has been successfully uploaded to the translation app. Your file is being translated. The translated file will automatically be downloaded shortly, please wait (up to 2 minutes). ",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => {
        setIsFlashbarDisplayed(false);
        getFile();
      },
      id: "message_1"
    }
  ]);
    // To upload files using Amplify
  
    useEffect(() => {
      async function getData() {
        await axios
          .get("https://api.customer-service.aandraca.people.aws.dev/messages")
          .then((response) => {
            // check if the data is populated
            console.log(response.data);
            setData(response.data);
            // you tell it that you had the result
            setLoadingData(false);
          });
      }
      if (loadingData) {
        // if the result is not ready so you make the axios call
        getData();
      }
    }, []);
  

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }
  
  // usage
  async function getFile(key) {
    const success = false;
    try {
      const result = await Storage.get(key, { download: true });
      downloadBlob(result.Body, key.split('/')[2]);
    } catch (error) {
      if(error.message === "NoSuchKey"){
        console.log("Caught the NoSuchKey error");
        console.log("Trying again in 10 seconds");
        setTimeout(getFile, 10*1000, key);
      }
    }   
  }
  function handleClick(e) {
    ref.current.click();
  }
  
  function handleFileChange(e) {
    const file = e.target.files[0];
    setFileToUpload(file);
  }

  async function uploadFile() {
    if (fileToUpload.name === ""){
      return;
    }
    setUploadStatus(true);
    try {
      let key =uuid() + "/" + fileToUpload.name;
      let result = await Storage.put("in/" + key, fileToUpload, {
        progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setUploadProgress(100*progress.loaded/progress.total);
            if (progress.loaded === progress.total) {
              setIsFlashbarDisplayed(true);
              setUploadStatus(false);
              setUploadProgress(0);
              setFileToUpload();
              return;
            }
        },
        errorCallback: (err) => {
            console.error('Unexpected error while uploading', err);
        }
        });
      console.log("Sucess");
      console.log(result);
      getFile("out/" + key);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }


  return (
    <ContentLayout header={<Header variant="h1">Pending messages</Header>}>
      {isFlashbarDisplayed && <Flashbar items={flashbarItems} />}
        <Table
      columnDefinitions=
      {[
        {
          id: "name",
          header: "Customer name",
          cell: e => e.name,
          width: 270,
          minWidth: 200,
          sortingField: "name",
          isRowHeader: true
        },
        {
          id: "aggresivityScore",
          header: "Aggresivity Score",
          cell: e => e.aggresivityScore,
          width: 190,
          minWidth: 190,
          sortingField: "aggresivityScore"
        },
        {
          id: "resumedMessage",
          header: "Resumed message",
          cell: e => e.resumed,
          width: 400,
          minWidth: 400
        },
        {
          id: "rawMessage",
          header: "Raw message",
          cell: e => e.raw,
          width: 200,
          minWidth: 170,
          whiteSpace: 'unset'
        }
      ]}
      items={data}
      loadingText="Loading resources"
      resizableColumns
      wrapLines={true}
      selectedItems={[{ name: "Item 2" }]}
      empty={
        <Box
          margin={{ vertical: "xs" }}
          textAlign="center"
          color="inherit"
        >
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      filter={
        <TextFilter
          filteringPlaceholder="Find resources"
          filteringText=""
        />
      }
      pagination={
        <Pagination currentPageIndex={1} pagesCount={1} />
      }
    />
    </ContentLayout>
    );
};