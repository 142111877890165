import React, { useRef, useState } from "react";
import { Amplify } from "aws-amplify";
import { Storage, AWSS3Provider, S3ProviderPutConfig } from "@aws-amplify/storage";
import {v4 as uuid} from "uuid";
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Form,
  FormField,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
  Input
} from "@cloudscape-design/components";

import awsconfig from "../constants/amplifyConfiguration"; 



Amplify.configure(awsconfig);


export default function CustomerMessageForm() {
  const ref = useRef()
  const [name, setName] = useState("John Doe");
  const [message, setMessage] = useState("");
  const [ fileToUpload, setFileToUpload ] = useState();
  const [ uploadStatus, setUploadStatus ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ isFlashbarDisplayed, setIsFlashbarDisplayed ] = useState(false);
  const [ flashbarItems, setFlashbarItems ] = useState([
    {
      type: "success",
      content: "The file has been successfully uploaded to the translation app. Your file is being translated. The translated file will automatically be downloaded shortly, please wait (up to 2 minutes). ",
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => {
        setIsFlashbarDisplayed(false);
        getFile();
      },
      id: "message_1"
    }
  ]);
    // To upload files using Amplify

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }
  
  // usage
  async function getFile(key) {
    const success = false;
    try {
      const result = await Storage.get(key, { download: true });
      downloadBlob(result.Body, key.split('/')[2]);
    } catch (error) {
      if(error.message === "NoSuchKey"){
        console.log("Caught the NoSuchKey error");
        console.log("Trying again in 10 seconds");
        setTimeout(getFile, 10*1000, key);
      }
    }   
  }
  function handleClick(e) {
    ref.current.click();
  }
  
  function handleFileChange(e) {
    const file = e.target.files[0];
    setFileToUpload(file);
  }

  async function sendMessage() {
    if (message.value === "" || name.value === ""){
      return;
    }
    try {
      console.log(message);
      console.log(name);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ raw: message, name: name })
      };
      fetch('https://api.customer-service.aandraca.people.aws.dev/messages', requestOptions)
        .then(response => response.json());
      console.log("Sucess");
      setName("");
      setMessage("");
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }




  return (
    <ContentLayout header={<Header variant="h1">Send a message to customer support</Header>}>
      {isFlashbarDisplayed && <Flashbar items={flashbarItems} />}
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {
                  <Button variant="primary" iconName="upload" onClick={sendMessage}>
                    Send message
                  </Button>
            }
          </SpaceBetween>
        }
      >
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Full name">              
              <Input 
                onChange={( {detail}) => setName(detail.value)}
                value={name}
              />            
            </FormField> 
            <FormField label="Your message">
              <Input 
                onChange={( {detail}) => setMessage(detail.value)}
                value={message}
              />            
            </FormField>       
          </SpaceBetween>
        </Container>
      </Form>
    </ContentLayout>
    );
};