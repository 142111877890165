import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import {
  AppLayout,
  BreadcrumbGroup,
  TopNavigation,
} from "@cloudscape-design/components";

import "./styles.css";
import '@aws-amplify/ui-react/styles.css';

import { Navigation } from "./components";




import CustomerMessageForm from "./pages/CustomerMessageForm";
import CustomerMessagesTable from "./pages/CustomerMessagesTable";


function App() {
  const [ isNavigationOpen , setIsNavigationOpen ] = useState(false);
  return (
    <>
      <TopNavigation
        identity={{
          // logo: { src: <AiFillCar /> },
          title: `Customer Service Analysis `,
          href: '/',
        }}
        i18nStrings={{
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All'
        }}
      />
      {/* <Button onClick={signOut}>Sign out</Button> */}
      <AppLayout
        headerSelector="[data-id='top-navigation]"
        navigation={<Navigation />}
        // breadcrumbs={<BreadcrumbGroup />}
        navigationOpen={isNavigationOpen}
        onNavigationChange={() => setIsNavigationOpen(!isNavigationOpen)}
        content={
          <Router>
            <Routes>
              <Route path="/*" element={<CustomerMessagesTable />} />
              <Route path="/form" element={<CustomerMessageForm />} />
            </Routes>
          </Router>
        }
      />
    </>
  );
}

export default App;