import { SideNavigation } from "@cloudscape-design/components";

const items = [
  { type: "link", text: "Messages table", href: "/table"},
  { type: "link", text: "Send message", href: "/form"}
];

export default function Navigation() {
  return (
    <>
      <SideNavigation
        activeHref={window.location.pathname}
        header={{ href: "#/", text: "Services" }}
        items={items}
      />
    </>
  )
}
